import * as React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from "@material-ui/core/Grid";
import { Layout } from "../../components/Layout";
import { CardMedia } from "@material-ui/core";
import { Slide } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Seo from "../../components/Seo/Seo";
import { CallbackBanner } from "../../components/CallbackBanner";

import GardeningImg from "../../images/service-grid/trimming_hedge.jpeg";

import { useStyles } from "../../pagesStyles/aboutUs.styling";

const Gardening = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");

  const breadcrumbs = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Services",
      link: "/services",
    },
    {
      name: "Gardening",
    },
  ];

  return (
    <Layout gutter marginTop breadcrumb={breadcrumbs}>
      <Seo title="Gardening" />
      <section style={{ padding: desktop ? 0 : "0 5px"  }}>
        <Grid container direction={desktop ? "row" : "column"} spacing={5}>
          <Grid item xs={desktop ? 6 : 12}>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              <strong>Gardening</strong>
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
            From mowing lawns to trimming hedges, maintenance of vegetable gardens, planting, 
            and weeding. We can keep your garden in shape for you to enjoy.
            </Typography>
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <Slide direction="right" in timeout={700}>
              <CardMedia
                style={{ height: "60vh" }}
                image={GardeningImg}
                // alt={altTag}
                classes={{ root: classes.cardImageL }}
                component="img"
              />
            </Slide>
          </Grid>
        </Grid>
      </section>
      <CallbackBanner/>
    </Layout>
  );
};

export default Gardening;
